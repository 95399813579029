import React from "react";
import { Loader, LoaderW } from "../../1-atoms/Icons/24 ";

interface LoaderProps {
  loaderLabel: string;
  color: "pink" | "white";
}

const LoaderComponent: React.FC<
  LoaderProps & React.AllHTMLAttributes<HTMLDivElement>
> = ({ loaderLabel, color = "pink", ...props }) => {
  return (
    <div {...props}>
      {color === "pink" && (
        <Loader aria-hidden="true" className={"animate-spin"} />
      )}
      {color === "white" && (
        <LoaderW aria-hidden="true" className={"animate-spin"} />
      )}
      <span className={"visually-hidden"} aria-live="polite" role="status">
        {loaderLabel}
      </span>
    </div>
  );
};

export default LoaderComponent;
